document.addEventListener("DOMContentLoaded", function () {
    const container = document.getElementById("textureContainer");
    const textures = [
        "lines",
        "dots",
        "zigzag",
        "checkerboard",
        "stripes",
        "waves",
        "aqua-glass",
    ];
    const shapes = ["circle", "square", "imperfect"];
    const colors = [
        "#3498db", // Sky Blue
        "#1abc9c", // Turquoise
        "#2ecc71", // Emerald Green
        "#e67e22", // Carrot Orange
        "#9b59b6", // Amethyst Purple
        "#f1c40f", // Sunflower Yellow
        "#e74c3c", // Alizarin Red
        "#34495e", // Wet Asphalt (Dark Blue)
        "#16a085", // Green Sea
        "#d35400", // Pumpkin Orange
    ];
    // Grid settings
    const gridColumns = 5;
    const gridRows = 3;
    const cellWidth = window.innerWidth / gridColumns;
    const cellHeight = 300 / gridRows;
    const grid = Array(gridRows)
        .fill()
        .map(() => Array(gridColumns).fill(0));

    function createRandomPolygon() {
        const points = [];
        const numPoints = Math.floor(Math.random() * 5) + 9; // 3 to 7 points
        for (let i = 0; i < numPoints; i++) {
            const angle = (i / numPoints) * 2 * Math.PI;
            const radius = 50 + Math.random() * 30; // Vary the radius
            const x = 50 + radius * Math.cos(angle);
            const y = 50 + radius * Math.sin(angle);
            points.push(`${x}% ${y}%`);
        }
        return `polygon(${points.join(", ")})`;
    }

    function createTexture() {
        const texture = document.createElement("div");
        texture.classList.add("texture");

        const textureType =
            textures[Math.floor(Math.random() * textures.length)];
        texture.classList.add(textureType);

        if (textureType === "aqua-glass") {
            texture.style.backgroundColor = "rgba(173, 216, 230, 0.3)"; // Light blue with transparency
            texture.style.backdropFilter = "blur(10px)";
            texture.style.boxShadow = "0 4px 30px rgba(0, 0, 0, 0.1)";
            texture.style.border = "1px solid rgba(255, 255, 255, 0.3)";
        } else {
            // Apply existing styles for other texture types
            // ... (existing code for other textures)
        }

        // Randomly choose between texture or shape
        if (Math.random() < 0.3) {
            const shape = shapes[Math.floor(Math.random() * shapes.length)];
            texture.classList.add(shape);
            if (shape === "imperfect") {
                texture.style.clipPath = createRandomPolygon();
            }
        }

        // Find a suitable cell for the texture
        let row, col;
        do {
            row = Math.floor(Math.random() * gridRows);
            col = Math.floor(Math.random() * gridColumns);
        } while (grid[row][col] >= 2); // Allow up to 2 textures per cell

        grid[row][col]++;

        const size = 50 + Math.random() * 300; // Slightly reduced max size
        const rotation = Math.random() * 360;

        // Position within the cell, allowing some overlap
        const x =
            col * cellWidth -
            size * 0.25 +
            Math.random() * (cellWidth - size * 0.5);
        const y =
            row * cellHeight -
            size * 0.25 +
            Math.random() * (cellHeight - size * 0.5);

        const color1 = colors[Math.floor(Math.random() * colors.length)];
        const color2 = colors[Math.floor(Math.random() * colors.length)];
        const gradientAngle = Math.floor(Math.random() * 360);
        const gradientStop = Math.floor(Math.random() * 100);

        texture.style.width = `${size}px`;
        texture.style.height = `${size}px`;
        texture.style.transform = `rotate(${rotation}deg)`;
        texture.style.left = `${x}px`;
        texture.style.top = `${y}px`;

        // Combine gradient with texture
        texture.style.backgroundImage = `
        linear-gradient(${gradientAngle}deg, ${color1} ${gradientStop}%, ${color2}),
        ${getTextureBackground(textureType)}
    `;
        texture.style.backgroundBlendMode = "overlay";
        texture.classList.add("animate", "--fade-in");

        // Randomly apply noise filter
        if (Math.random() < 0.5) {
            texture.style.filter = "url(#noise)";
        }

        container.appendChild(texture);
    }

    function getTextureBackground(textureType) {
        switch (textureType) {
            case "lines":
                return "repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(0, 0, 0, 0.1) 5px, rgba(0, 0, 0, 0.1) 10px)";
            case "dots":
                return "radial-gradient(circle, rgba(0, 0, 0, 0.1) 1px, transparent 1px)";
            case "zigzag":
                return `
                linear-gradient(135deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%) -10px 0,
                linear-gradient(225deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%) -10px 0,
                linear-gradient(315deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%),
                linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%)
            `;
            case "checkerboard":
                return `
                linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%), 
                linear-gradient(-45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%), 
                linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.1) 75%), 
                linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.1) 75%)
            `;
            case "stripes":
                return "repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 10px, transparent 10px, transparent 20px)";
            case "waves":
                return `
                radial-gradient(circle at 100% 50%, transparent 20%, rgba(0,0,0,0.1) 21%, rgba(0,0,0,0.1) 34%, transparent 35%, transparent),
                radial-gradient(circle at 0% 50%, transparent 20%, rgba(0,0,0,0.1) 21%, rgba(0,0,0,0.1) 34%, transparent 35%, transparent) 0 -50px
            `;
            case "aqua-glass":
                return "linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))";
            default:
                return "none";
        }
    }

    // Create textures
    for (let i = 0; i < 5; i++) {
        createTexture();
    }
});
